<template>
  <div class="container">
   
    <div
      class="map"
      ref="map"
      id="map"
      :style="{ height: tableHeight + 'px' }"
    >
     <!-- 数据显示区域开始  -->
    <div class="input-card">
      <el-button type="primary" @click="startEdit" v-if="!isReadyAdd">
        去编辑
      </el-button>
      <el-button type="primary" @click="deleteHandel" v-if="!isReadyAdd">
        删除
      </el-button>

      <el-button type="primary" @click="addArea" v-if="isReadyAdd">
        增加禁入区域
      </el-button>
    </div>
    </div>
    <!-- 数据显示区域结束  -->
  </div>
</template>
<script>
import { getGisRangePoints, deleteGisRangePoints } from "../../api/gis";
import gismixin from "../../mixin/gisMixin";
export default {
  mixins: [gismixin],
  mounted() {
    this.resetSize();
   // this.initMap();
  },
  data() {
    return {
      //idCanEdit: true,
      pathList: [
        // {
        //   extData: {},
        //   path: [
        //     [106.403322, 29.920255],
        //     [106.410703, 29.897555],
        //     [106.402292, 29.892353],
        //   ],
        // },
      ],
      gisRangeName: "6eed4b7df3a149cd9c1f2be787b1fbf9", //这个value6eed4b7df3a149cd9c1f2be787b1fbf9是写死的，后端规定
      // polygon:{},//多边形区域对象
      map: {}, //地图对象
      polyEditorList: [], //多边形编辑对象
      isReadyAdd: true, //是否准备增加多边形，模式是，主要是为了显示增加禁入区域按钮还是修改删除按钮
      polygonOptions: {
        default: {
          strokeColor: "#FF33FF",
          fillColor: "#1791fc",
        },
        selected: {
          strokeColor: "#00ff00",
          fillColor: "#ff0066",
        },
      },
      selectedPolygon: "", //当前选中的多边形
    };
  },
  beforeDestroy() {
    // 销毁地图，并清空地图容器
    this.map.destroy();
  },
  methods: {
    resetSize() {
      let h =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.tableHeight = h - 150;
    },
    //增加区域
    addArea() {
      this.$router.push({
        path: "/home/area/editGisArea",
        query: { type: "3" },
      });
    },
    //删除区域
    deleteHandel() {

       
      this.$confirm("删除区域", "确定要删除当前选中区域?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let extData = this.selectedPolygon.getExtData();
          let data = { gisRangeName: extData.gisRangeName };
          deleteGisRangePoints(data)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success("删除成功");
                //this.getGisRangePointsData();
                this.map.remove(this.selectedPolygon);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 开始编辑多边形
    startEdit() {
      let extData = this.selectedPolygon.getExtData();
      this.$router.push({
        path: "/home/area/editGisArea",
        query: { type: "3", gisRangeName: extData.gisRangeName },
      });
    },
    // //结束编辑多边形
    // endEdit() {
    //   this.idCanEdit = true;
    //   this.polyEditor.close();
    //   this.savePath();
    // },
    //保存围栏路径
    // savePath() {
    //   let polygonPaths = this.polygon.getPath();
    //   let savePathList = [];
    //   for (var i = 0; i < polygonPaths.length; i++) {
    //     let item = polygonPaths[i];
    //     savePathList.push({ lat: item.lat, lng: item.lng });
    //   }
    //   let pathStr = JSON.stringify(savePathList);
    //   let data = {
    //     gisRangeName: this.gisRangeName,
    //     type: "2",
    //     gisRangePoint: pathStr,
    //   };
    //   updateGisRangePoints(data)
    //     .then((res) => {
    //       if (res.code == 1) {
    //         this.$message.success("保存成功");
    //       } else {
    //         this.$message.error(res.code);
    //       }
    //     })
    //     .catch((e) => {});
    // },
    initMap() {
      const container = this.$refs.map;
      var map = new AMap.Map(container, {
        zoom: 12, //设置地图显示的缩放级别
        center:  this.mapCenter, //设置地图中心点坐标
        //layers: [new AMap.TileLayer.Satellite(), new AMap.TileLayer.RoadNet()], //设置图层,可设置成包含一个或多个图层的数组
        //mapStyle: "amap://styles/whitesmoke", //设置地图的显示样式
        viewMode: "2D", //设置地图模式
        lang: "zh_cn", //设置地图语言类型
        resizeEnable: true,
        polygonList: [], //禁入区域列表
      });
      // 同时引入工具条插件，比例尺插件和鹰眼插件
      AMap.plugin(
        [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.MapType",
          "AMap.Geolocation",
        ],
        function () {
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          map.addControl(new AMap.ToolBar());

          // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          map.addControl(new AMap.Scale());

          // 在图面添加鹰眼控件，在地图右下角显示地图的缩略图
          map.addControl(new AMap.OverView({ isOpen: true }));

          // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
          map.addControl(new AMap.MapType());

          // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
          map.addControl(new AMap.Geolocation());
        }
      );
      this.map = map;
      let that = this;
      this.map.on("complete", function () {
        that.matStatusIsOk = true;
        //重新查询数据
        that.getGisRangePointsData();
      });
      this.map.on("click", function () {
        that.isReadyAdd = true; //修改当前增加按钮状态为可用

        if (that.selectedPolygon) {
          that.selectedPolygon.setOptions({
            strokeColor: that.polygonOptions.default.strokeColor,
            fillColor: that.polygonOptions.default.fillColor,
          });
        }
      });
    },
    //获取设置的围栏点信息
    getGisRangePointsData() {
       
      let data = { type: "3" };
      getGisRangePoints(data)
        .then((res) => {
          if (res.code == 1) {
            let list = res.data.list; //得到所有多边形数据列表
            this.pathList=[];//清空历史数据
            for (var i = 0; i < list.length; i++) {
              //循环每个多边形数据整体对象
              let item = list[i];
              let pointStr = item.gisRangePoint; //拿到对应多边形数据中的点数据，
              try {
                let pointArray = JSON.parse(pointStr); //但是是字符串，所以需要解析成为数组
                let pathArray = []; //下面这个循环拼装成为map对象需要的 坐标数组结构 [[lng,lat],[lng,lat]]
                for (var x = 0; x < pointArray.length; x++) {
                  let pointArrayItem = pointArray[x];
                  pathArray.push([pointArrayItem.lng, pointArrayItem.lat]);
                }
                //每个多边形点数据必须大于3个，不然怎么搞？
                if (pathArray.length >= 3) {
                  //这里要给每个多边形增加 路径数据 和当前多边形其后台对象他数据
                  let pathListItem = { extData: item, path: pathArray };
                  //将当前这个循环中的对应的多边形对象信息放到this.pathList ，便于后面的使用
                  this.pathList.push(pathListItem);
                }
              } catch (error) {
                console.error(error);
              }
            }
           
            //开始绘制多边形
            this.initPolygon();
          } else {
            console.log(res.code);
          }
        })
        .catch((e) => {});
    },
    initPolygon() {

       
      for (var i = 0; i < this.pathList.length; i++) {
        let polygon = new AMap.Polygon({
          path: this.pathList[i].path, //设置多边形边界路径
          strokeColor: this.polygonOptions.default.strokeColor, //线颜色
          strokeWeight: 6, //线宽
          strokeOpacity: 0.2, //线透明度
          fillOpacity: 0.4, //填充透明度
          fillColor: this.polygonOptions.default.fillColor, //填充色
          zIndex: 50,
        });
        let that = this;
        //多边形点击事件
        polygon.on("click", function () {
          //移除上一次选中的区域的效果
          if (that.selectedPolygon) {
             
            that.selectedPolygon.setOptions({
              strokeColor: that.polygonOptions.default.strokeColor,
              fillColor: that.polygonOptions.default.fillColor,
            });
          }

          that.isReadyAdd = false;
          that.selectedPolygon = this; //把当前选中的多边形给到全局，便于其他地方使用
          //当点击某个多边形的时候，改变当前多边形的颜色，便于知道当前选中区域

          this.setOptions({
            strokeColor: that.polygonOptions.selected.strokeColor,
            fillColor: that.polygonOptions.selected.fillColor,
          });
        });
        // polygon.on("rightclick", function () {
        //   console.log(this.getExtData());
        //   this.setOptions({ strokeColor: "#00ff00" });
        // });

        polygon.setExtData(this.pathList[i].extData);
        this.map.add(polygon);
        this.polyEditorList.push(new AMap.PolyEditor(this.map, polygon));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.input-card {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 100000;
}
.map {
  width: 100%;
  height: 300px;
}
</style>